<template>
  
    <div class="col-md-12">
      <div class="box box-primary">
        <div class="box-body">
            <div class="card col-md-6">
                <h5 class="card-header">Form Validation Example</h5>
                <div class="card-body">
                    <Form  :validation-schema="schema" v-slot="{ errors }">
                        <div class="form-row">
                            <div class="form-group col">
                                <label>Title </label>
                                <Field name="title" as="select" class="form-control"      >
                                    <option value=""></option>
                                    <option value="Mr">Mr</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Miss">Miss</option>
                                    <option value="Ms">Ms</option>
                                </Field>
                                <div class="invalid-feedback" v-html="errors.title"></div>
                            </div>
                            <div class="form-group col-5">
                                <label>First Name <span class="required">(Required)</span></label>
                                <Field name="firstName" type="text" class="form-control" v-model = "firstName" v-bind:class="[ (errors.firstName ? 'is-invalid' : ''), (typeof(firstName) == 'undefined' ? 'required' : '')]" />
                                <div class="invalid-feedback" v-html="errors.firstName"></div>
                            </div>
                            <div class="form-group col-5">
                                <label>Last Name <span class="required">(Required)</span></label>
                                <Field name="lastName" type="text" class="form-control" v-model = "lastName" v-bind:class="[ (errors.lastName ? 'is-invalid' : ''), (typeof(lastName) == 'undefined' ? 'required' : '')]" />
                                <div class="invalid-feedback" v-html="errors.lastName"></div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col">
                                <label>Date of Birth</label>
                                <Field name="dob" type="date" class="form-control" v-bind:class="[errors.dob ? 'is-invalid' : '']" />
                                <div class="invalid-feedback" v-html="errors.dob"></div>
                            </div>
                            <div class="form-group col">
                                <label>Email</label>
                                <Field name="email" type="text" class="form-control" v-bind:class="[errors.email ? 'is-invalid' : '']" />
                                <div class="invalid-feedback" v-html="errors.email"></div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col">
                                <label>Password</label>
                                <Field name="password" type="password" class="form-control" v-bind:class="[errors.password ? 'is-invalid' : '']" @input="password_check" v-model="message" />
                            
                                <div class="invalid-feedback" v-html="errors.password"></div>
                                <p class="frmvalidation text-red" :class="{'frmvalidation-passed' : message.length > 12}"><i class="fa" :class="message.length > 12 ? 'hide' : 'fa-times'"></i> Minimum 12 characters long</p>
                                
                            </div>
                            <div class="form-group col">
                                <label>Confirm Password</label>
                                <Field name="confirmPassword" type="password" class="form-control" v-bind:class="[errors.confirmPassword ? 'is-invalid' : '']" />
                                <div class="invalid-feedback" v-html="errors.confirmPassword"></div>
                            </div>
                        </div>
                        <div class="form-group form-check">
                            <Field name="acceptTerms" type="checkbox" id="acceptTerms" value="true" class="form-check-input" v-bind:class="[errors.acceptTerms ? 'is-invalid' : '']" />
                            <label for="acceptTerms" class="form-check-label">Accept Terms & Conditions</label>
                            <div class="invalid-feedback" v-html="errors.acceptTerms"></div>
                        </div>
                        <div class="form-group">
                        <button type="submit" class="btn btn-primary mr-1">Register</button>

                        <button type="button" class="btn btn-primary mr-1"  v-on:click="myFunction()">Show Submit Success Alert</button>

                        
                        </div>
                    </Form>
                </div>
            </div>   
        </div> 
      </div>
    </div> 

    <div class="col-md-6">
      <div class="box box-primary">
         <div class="box-header with-border"><i class="fa fa-exchange"></i> <h3 class="box-title head-title-padding">Input Types</h3>
         </div>
        <div class="box-body">
           <div class="row">
             <div class="col-md-12">
                <div class="form-group">
                <label class="col-md-12 control-label control-label text-left">Team</label>
                <div class="col-12">
                    <div class="simple-label multiple pos-rel">
                        <div class="float-btn-group"><button class="margin-5 icon-button button-delete" type="button"><i class="fa fa-times-circle"></i></button><input type="text" class="form-control"></div>
                        <div class="float-btn-group"><button class="margin-5 icon-button button-delete" type="button"><i class="fa fa-times-circle"></i></button><input type="text" class="form-control"></div>
                        <div class="float-btn-group"><button class="margin-5 icon-button button-delete" type="button"><i class="fa fa-times-circle"></i></button><input type="text" class="form-control"></div>
                        <button class="margin-5 icon-button button-add-row" type="button"><i class="fa fa-plus-circle"></i></button>
                    </div>
                    <div class="form-group">
                        <label class="col-md-12 control-label control-label text-left">Team (with description)</label>
                        <div class="col-12">
                            <div class="simple-label multiple pos-rel">
                            <div class="float-btn-group">
                                <button class="margin-5 icon-button button-delete" type="button"><i class="fa fa-times-circle"></i></button>
                                <div class="input-with-desc">
                                    <select class="form-control group-property-1">
                                        <option value="Fax">Fax</option>
                                        <option value="Home">Home</option>
                                        <option value="Mobile">Mobile</option>
                                    </select>
                                    <input type="text" class="form-control group-property-2">
                                </div>
                            </div>
                            <div class="float-btn-group">
                                <button class="margin-5 icon-button button-delete" type="button"><i class="fa fa-times-circle"></i></button>
                                <div class="input-with-desc">
                                    <select class="form-control group-property-1">
                                        <option value="Fax">Fax</option>
                                        <option value="Home">Home</option>
                                        <option value="Mobile">Mobile</option>
                                    </select>
                                    <input type="text" class="form-control group-property-2">
                                </div>
                            </div>
                            <div class="float-btn-group">
                                <button class="margin-5 icon-button button-delete" type="button"><i class="fa fa-times-circle"></i></button>
                                <div class="input-with-desc">
                                    <select class="form-control group-property-1">
                                        <option value="Fax">Fax</option>
                                        <option value="Home">Home</option>
                                        <option value="Mobile">Mobile</option>
                                    </select>
                                    <input type="text" class="form-control group-property-2">
                                </div>
                            </div>
                            <button class="margin-5 icon-button button-add-row" type="button"><i class="fa fa-plus-circle"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

             </div>
             <div class="col-md-12">
               <div class="form-group bg-group">
                    <label class="col-md-12 control-label text-left">Interaction Property Based Skills</label>
                    <div class="col-md-12">
                        <div class="form-group group-bg group-input detail-g-borderbottom">
                            <label class="col-md-12 control-label text-left group-input-label">Interaction Property</label>
                            <div class="col-md-12">
                              <Multiselect   
                                v-model="value"
                                placeholder="Select or search for option"
                                :options="['Answer Property(QA)', 'Authorization Type (QA)', 'Case Property']"
                                />
                            </div>
                        </div>
                        <div class="form-group group-bg group-input detail-g-borderbottom detail-g-bordertop">
                            <label class="col-md-12 control-label text-left  group-input-label">Interaction Property Value</label>
                            <div class="col-md-12">														
                                <input type="text" class="form-control group-input-item" id="propertyValue">
                            </div>
                        </div>
                       
                    </div>
                    </div>

             </div>
             <div class="col-md-12">
                <div class="form-group">
                    <label for="inputPassword3" class="col-12 control-label text-left">Segment List</label>
                    <div class="col-12">
                            <Multiselect
                                v-model="value"
                                placeholder="Select Segment"
                                label="name"
                                :searchable="true"
                                :options="[
                                    { value: 'iService 1', name: 'iService',level:'1'},
                                    { value: 'Sales', name: 'Sales ',level:'1'},
                                    { value: 'Marketing', name: 'Marketing ',level:'1'}
                                ]"
                                >
                                <template v-slot:option="{ option }">
                                    <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                </template>
                            </Multiselect>
                    </div>
                </div>

                <div class="form-group">
                     <label for="inputPassword3" class="col-12 control-label text-left">Topic List</label>
                    <div class="col-md-12">
                        <Multiselect
                            v-model="value"
                            placeholder="-Select Topic-"
                            label="name"
                            :groups="true"
                            :searchable="true"
                            :options="[
                                {
                                label: '_iService',
                                options:  [
                                    { value: 'All Topic', name: 'All Topic',level:'1'},
                                    { value: 'Feedback', name: 'Feedback',level:'2'},
                                    
                                ],
                                },
                                {
                                label: 'Sales',
                                options:  [
                                    { value: 'All Topic', name: 'All Topic',level:'1'},
                                    { value: 'Feedback', name: 'Feedback',level:'2'},
                                    
                                ],
                                }
                            ]"
                            >
                            <template v-slot:option="{ option }">
                            <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                            </template>
                        </Multiselect>
                    </div> 
                </div>

                <div class="form-group">
                   <label for="inputPassword3" class="col-12 control-label text-left">Multiselect With Option</label>
                   <div class="col-md-12">
                        <Multiselect
                            v-model="value"
                            mode="multiple"
                            placeholder="Select or search for notification template"
                            :searchable="true"
                            :options="{
                                template1: 'Template 1',
                                template2: 'Template 2',
                                template3: 'Template 3',
                                template4: 'Template 4'
                            }"
                        />
                   </div>
                </div>

                <div class="form-group">
                   <label for="inputPassword3" class="col-12 control-label text-left">Multiselect with Group</label>
                   <div class="col-md-12">
                       <Multiselect   
                        v-model="value"
                        placeholder="Select or search for option"
                        mode="tags"
                        :options="['Jhon Doe', 'Steve Smith', 'Scott']"
                        />
                   </div>
                </div>
                <div class="form-group">
                   <label for="inputPassword3" class="col-12 control-label text-left">Agent List</label>
                   <div class="col-md-12">
                      <Multiselect
                        v-model="value"
                        placeholder="Select or search for an agent in the list"
                        label="name"
                        :searchable="true"
                        :options="[
                          { value: 'alek packard', name: 'Alek Packard',email:'alek.packard@example.com',status:'',disabled: false ,level:'1' },
                          { value: 'jacob smith', name: 'Jacob Smith',email:'jacob.smith@example.com',status:'notavailable',disabled: true ,level:'1' },
                          { value: 'jonnie hewlett', name: 'Jonnie Hewlett',email:'jonnie.hewlett@example.com',status:'logout',disabled: false,level:'1'  },
                        ]"
                      >
                        <template v-slot:singlelabel="{ value }">
                          <div class="multiselect-single-label">
                              {{ value.name }}
                          </div>
                        </template>

                        <template v-slot:option="{ option }">
                          <div>
                            {{ option.name }} <span v-if=" option.status == 'notavailable'"><i class="fa  fa-ban text-red"   data-bs-toggle="tooltip" data-bs-placement="bottom"  title="This agent is not available because they  are logged out and have the Empty Inbox setting"></i></span>
                            <span v-if=" option.status == 'logout'"><i class="fa fa-fw fa-sign-out text-red" data-bs-toggle="tooltip" data-bs-placement="bottom" title="logged out agent"></i></span>
                            <br/> {{ option.email}}
                          </div>
                        </template>
                      </Multiselect>
                    </div>
                </div>
                <div class="form-group">
                   <label for="inputPassword3" class="col-12 control-label text-left">Forward Segment</label>
                   <div class="col-md-12">
                        <Multiselect
                          v-model="value"
                          placeholder="Select or search for a mailbox to receive interaction"
                          label="name"
                          :groups="true"
                          :searchable="true"
                          :options="[
                            {
                              label: 'Customer Success',
                              options:  [
                                  { value: 'sales', name: 'Sales',email:'sales@1to1service.com',level:'1'},
                                  { value: 'technical', name: 'Technical',email:'technical@1to1service.com',level:'1'},
                              ],
                            },
                            {
                              label: 'Human Resource',
                              options:  [
                                  { value: 'benefits', name: 'Banefits',email:'benefits@1to1service.com',level:'1'},
                                  { value: 'hr', name: 'Human Resource',email:'hr@1to1service.com',level:'1'},
                              ],
                            }
                          ]"
                        >
                        <template v-slot:option="{ option }">
                            <span v-bind:class="'op-level-' + option.level"> {{ option.name }} 
                            <br/> {{ option.email}}
                            </span>
                        </template>
                      </Multiselect>

                    </div>
                </div>
             </div>
           </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="box box-primary">
         <div class="box-header with-border"><i class="fa fa-exchange"></i> <h3 class="box-title head-title-padding">Send Alert Notification</h3>
         </div>
        <div class="box-body">
            <div class="form-group">
              <label for="inputPassword3" class="col-12 control-label text-left">Segment List</label>
                <div class="col-12">
                        <Multiselect
                            v-model="segment_name"
                            placeholder="Select Segment"
                            label="name"
                            :searchable="true"
                            :options="[
                                { value: 'iService 1', name: 'iService',level:'1'},
                                { value: 'Sales', name: 'Sales ',level:'1'},
                                { value: 'Marketing', name: 'Marketing ',level:'1'}
                            ]"
                            >
                            <template v-slot:option="{ option }">
                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                            </template>
                        </Multiselect>
                </div>
            </div>
            <div class="segmentName">
                 <div class="form-group">
                    <label for="inputPassword3" class="col-12 control-label text-left">Alert Notification Templates</label>
                    <div class="col-12" v-if="segment_name">
                        <Multiselect
                                v-model="value"
                                placeholder="Select or search for a notification template in the list"
                                label="name"
                                :groups="true"
                                :searchable="true"
                                :options="[
                                    {
                                    label: 'iService 1',
                                    options:  [
                                        { value: 'Agent Notify', name: 'Agent Notify',level:'1'},
                                        { value: 'Agent Reply', name: 'Agent Reply',level:'1'}
                                    ],
                                    },
                                    {
                                    label: 'Sales',
                                        options:  [{}],
                                    }
                                    ,
                                    {
                                    label: 'Marketing',
                                        options:  [
                                           { value: 'Customer Alerts', name: 'Customer Alerts',level:'1'},
                                        ],
                                    }
                                ]"
                                >
                                <template v-slot:option="{ option }">
                                   <span v-if="Object.keys(option).length >= 0">
                                      <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                   </span>
                                    <span v-if="Object.keys(option).length <= 0">
                                       There are no alert notification templates in the segment.
                                    </span>
                                </template>
                        </Multiselect>
                    </div>
                    <div class="col-12" v-else>
                        <Multiselect   
                                v-model="value"
                                 placeholder="Select or search for a notification template in the list"
                                mode="tags"
                                :options="[{}]">
                                <template v-slot:option="{ option }">
                                    <span v-if="Object.keys(option).length <= 0">
                                       You must select segment to choose auto response.</span>
                                </template>
                        </Multiselect>
                    </div>
                </div>
            </div>
        </div>
     </div>
    </div>
    <!--toast Message-->
       <div id="snackbar" class="alert alert-success" :class="[ showSnakeBarMessage == true ? 'showtoast' : '']">
        <div class="message-container"><div class="icon"><i class="icon fa fa-check-circle"></i></div><div class="message"><div> Agent Email is sent successfully. </div></div></div>
       </div>
    <!-- toast message-->
</template>

<script>
import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import { reactive,ref, onMounted } from 'vue'
export default {
    components: {
        Form,
        Field,
        Multiselect
    },
    data() {
        return {
          showSnakeBarMessage:false,
            message:       '',
            has_number:    false,
            has_lowercase: false,
            has_uppercase: false,
            has_special:   false,
            segment_name: false
        };
    },
    setup() {
        const schema = Yup.object().shape({
            title: Yup.string()
                .required('<span>Title is required</span>'),
            firstName: Yup.string()
                .required('<span>First Name is required</span>'),
            lastName: Yup.string()
                .required('<span>Last name is required</span>'),
            dob: Yup.string()
                .required('<span>Date of Birth is required</span>')
                .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Birth must be a valid date in the format YYYY-MM-DD'),
            email: Yup.string()
                .required('<span>Email is required</span>')
                .email('<span>Email is invalid</span>'),
            password: Yup.string()
               /* .min(6, '<span>Password must be at least 6 characters</span>')*/
                .required('<span>Password is required</span>'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match</span>')
                .required('<span>Confirm Password is required</span>'),
            acceptTerms: Yup.string()
                .required('<span>Accept Ts & Cs is required</span>')
        });

        const onSubmit = values => {
           
        }

        return {
            schema,
            onSubmit
        };
    },
    methods:{
        closeToastMessage: function(){
            this.showSnakeBarMessage  = false;
        },
        myFunction: function(){
            this.showSnakeBarMessage =  true;
            setTimeout(function(){ this.closeToastMessage();}.bind(this), 3000);  
        },
        password_check: function () {
            this.has_number    = /\d/.test(this.message);
            this.has_lowercase = /[a-z]/.test(this.message);
            this.has_uppercase = /[A-Z]/.test(this.message);
            this.has_special   = /[!@#\$%\^\&*\)\(+=._-]/.test(this.message);
        }
    }  
}
</script>